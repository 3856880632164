import React, { useEffect, useState } from "react";
import { fetchTransactHistory } from "../../../api/apiService";
import DashboardOverlay from "../DashboardOverlay";

const TransactionHistories = () => {
  const [transactionHistories, setTransactionHistories] = useState([]);
  const [typeFilter, setTypeFilter] = useState("Earn subscription"); // Default type filter
  const [startDate, setStartDate] = useState(""); // Default startDate
  const [endDate, setEndDate] = useState(""); // Default endDate

  // Fetch data with filters
  const fetchFilteredTransactions = async () => {
    try {
      const response = await fetchTransactHistory(
        typeFilter,
        startDate,
        endDate
      );
      console.log("API Response:", response); // Debug response

      const requestList = response.transactions;

      if (!Array.isArray(requestList)) {
        console.error("Unexpected response structure:", response);
        return;
      }

      setTransactionHistories(requestList);
    } catch (error) {
      console.error("Error fetching transaction histories:", error);
    }
  };

  useEffect(() => {
    fetchFilteredTransactions();
  }, [typeFilter, startDate, endDate]);

  return (
    <section className="relative pt-7 px-3 sm:px-8">
      <div className="mb-4 flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-4">
        <div>
          <label htmlFor="typeFilter" className="mr-2 text-lg">
            Type:
          </label>
          <select
            id="typeFilter"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            className="py-1 px-2 border rounded"
          >
            <option value="Earn subscription">Earn subscription</option>
            <option value="withdrawal">withdrawal</option>
          </select>
        </div>
        <div>
          <label htmlFor="startDate" className="mr-2 text-lg">
            Start Date:
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="py-1 px-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="endDate" className="mr-2 text-lg">
            End Date:
          </label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="py-1 px-2 border rounded"
          />
        </div>
        <button
          onClick={fetchFilteredTransactions}
          className="py-1 px-3 bg-[#f97729] text-white rounded shadow-md hover:bg-[#e06b25]"
        >
          Apply Filters
        </button>
      </div>
      <div className="bg-white shadow-md overflow-x-auto">
        <h4 className="w-screen py-2 text-white text-xl text-center bg-[#f97729] rounded-t sm:w-full">
          Transaction History
        </h4>
        <table className="w-screen text-center bg-white rounded sm:w-full">
          <thead>
            <tr className="text-lg border-b-2">
              <th className="px-4 py-2">User</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">Recipient Address</th>
              <th className="px-4 py-2">From Address</th>
              <th className="px-4 py-2">Transaction Hash</th>
              <th className="px-4 py-2">Charges</th>
              <th className="px-4 py-2">Transaction Type</th>
              <th className="px-4 py-2">Asset</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistories.map((request) => (
              <tr key={request._id} className="border-b">
                <td className="py-2 px-4">{request.user}</td>
                <td className="py-2 px-4">{request.amount}</td>
                <td className="py-2 px-4">{request.recipientAddress}</td>
                <td className="py-2 px-4">{request.fromAddress}</td>
                <td className="py-2 px-4">{request.mainTransactionHash}</td>
                <td className="py-2 px-4">{request.charges}</td>
                <td className="py-2 px-4">{request.transactionType}</td>
                <td className="py-2 px-4">{request.asset}</td>
                <td className="py-2 px-4">{request.transactionStatus}</td>
                <td className="flex justify-center space-x-2 py-2">
                  <button className="py-1 px-3 text-sm rounded shadow-md sm:text-base">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TransactionHistories;
