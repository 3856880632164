import { useState, useEffect } from "react";
import DashboardHomeBox from "./DashboardHomeBox";
import { fetchWithrawalRequest } from "../../../api/apiService";

const DashboardHome = () => {
  const [totalWithdrawalRequests, setTotalWithdrawalRequests] = useState([]);
  // Fetch data
  useEffect(() => {
    const fetchWithdrawalInfo = async () => {
      try {
        const response = await fetchWithrawalRequest();
        const totalRequest = response.totalWithdrawalsRequest;

        setTotalWithdrawalRequests(totalRequest);
      } catch (error) {
        console.error("Error fetching withdrawal requests:", error);
      }
    };

    fetchWithdrawalInfo();
  }, []);

  return (
    <section className="py-7 px-4">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <DashboardHomeBox
          heading="Total Users"
          total="0"
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total Deposit"
          total="0"
          borderColor="border-[#045544]"
          color="text-[#045544]"
        />
        <DashboardHomeBox
          heading="Total Withdrawal"
          total="0"
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total paid creators"
          total="0"
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total transaction fees"
          total="0"
          borderColor="border-[#045544]"
          color="text-[#045544]"
        />
        <DashboardHomeBox
          heading="Total daily users"
          total="0"
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total withdrawal request"
          total={totalWithdrawalRequests}
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total point claimed"
          total="0"
          borderColor="border-[#f97729]"
          color="text-[#f97729]"
        />
        <DashboardHomeBox
          heading="Total subscription"
          total="0"
          borderColor="border-[#045544]"
          color="text-[#045544]"
        />
      </div>
    </section>
  );
};

export default DashboardHome;
