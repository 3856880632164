import React, { useEffect, useState } from "react";
import { fetchWalletDetails, withdralFromUsers } from "../../../api/apiService";
import { toast } from "react-toastify";

const DashboardSmallNavigation = () => {
  const [walletDetails, setWalletDetails] = useState([]);
  const [withdraw, setWithdraw] = useState([]);

  // Fetch wallet details
  const fetchWalletDetail = async () => {
    try {
      const response = await fetchWalletDetails();
      console.log("API Response:", response);

      // Assuming response is an array of wallet details
      if (Array.isArray(response)) {
        setWalletDetails(response);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching wallet details:", error);
    }
  };

  const withdralFromUser = async () => {
    try {
      const response = await withdralFromUsers();
      console.log("API Response:", response);
      alert(`${response.message}`);
    } catch (error) {
      console.error("Error making withdrawal:", error);
    }
  };

  // Helper to get balance for a specific currency
  const getBalanceByCurrency = (currency) => {
    const wallet = walletDetails.find((wallet) => wallet.currency === currency);
    return wallet ? wallet.balance : "N/A";
  };

  // Helper to get balance for a specific currency
  const getAddressByCurrency = (currency) => {
    const wallet = walletDetails.find((wallet) => wallet.currency === currency);
    return wallet ? wallet.address : "N/A";
  };

  // Copy address to clipboard
  const copyToClipboard = (address) => {
    if (!address || address === "N/A") {
      alert("Address not available!");
      return;
    }
    navigator.clipboard.writeText(address).then(
      () => {
        alert("Address copied to clipboard!");
      },
      (err) => {
        alert("Failed to copy address.");
        console.error("Copy failed:", err);
      }
    );
  };

  // Fetch wallet details on component mount
  useEffect(() => {
    fetchWalletDetail();
  }, []);

  return (
    <section className="py-[29.5px] bg-gray-50">
      <div className="flex justify-between items-center px-4 sm:px-10">
        {/* Display SFC Balance */}
        <p className="text-sm sm:text-base">
          SFC Balance: {getBalanceByCurrency("SFC")}
        </p>

        {/* Display USDT Balance */}
        <p className="text-sm sm:text-base">
          USDT Balance: {getBalanceByCurrency("USDT")}
        </p>

        {/* Display BNB Balance */}
        <p className="text-sm sm:text-base">
          BNB Balance: {getBalanceByCurrency("BNB")}
        </p>

        {/* Button for withdrawing users' balance */}
        <button
          onClick={withdralFromUser}
          className="py-1 px-3 bg-[#f97729] text-white rounded text-sm sm:text-base hover:bg-[#e06b25]"
        >
          Withdraw users balance
        </button>

        {/* Wallet Address Display */}
        <button
          className="flex items-center space-x-1 text-sm sm:text-base"
          onClick={() => copyToClipboard(getAddressByCurrency("USDT"))}
        >
          <p>
            Wallet Address:{" "}
            <span className="text-gray-500">
              {getAddressByCurrency("USDT").replace(
                /^(.{4}).+(.{4})$/,
                "$1****$2"
              )}
            </span>
          </p>
        </button>
      </div>
    </section>
  );
};

export default DashboardSmallNavigation;
