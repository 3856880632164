import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// Fonts
import "./fonts/Providence.ttf";
import ProtectedRoute from "./components/Authenticated/ProtectedRoute";

// Dashboard
import LoginPage from "./pages/Dashboard/Login";
import RegisterPage from "./pages/Dashboard/Register";
import DashboardRootLayout from "./pages/Dashboard/DashboardRoot";
import DashboardHomePage from "./pages/Dashboard/DashboardHome";
import WithdrawalRequestPage from "./pages/Dashboard/WithdrawalRequest";
import TransactionHistory from "./pages/Dashboard/TransactionHistory";

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <RootLayout />,
  //   children: [
  //     {
  //       index: true,
  //       element: <HomePage />,
  //     },
  //     {
  //       path: "signup",
  //       element: <Signup />,
  //     },
  //     {
  //       path: "about-us",
  //       element: <AboutPage />,
  //     },
  //     {
  //       path: "contact-us",
  //       element: <ContactPage />,
  //     },
  //     {
  //       path: "myChat",
  //       element: <ChatPage />,
  //     },

  //     {
  //       path: "login",
  //       element: <LoginPage />,
  //     },
  //     {
  //       path: "register",
  //       element: <RegisterPage />,
  //     },
  //   ],
  // },

  {
    path: "/",
    element: <DashboardRootLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        index: true,
        element: (
          <ProtectedRoute>
            <DashboardHomePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "request",
        element: (
          <ProtectedRoute>
            <WithdrawalRequestPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "transaction-history",
        element: (
          <ProtectedRoute>
            <TransactionHistory />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const App = () => {
  return (
    <>
      {/* Add ToastContainer here */}
      <ToastContainer
        className="custom-toast-container"
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Provide the router */}
      <RouterProvider router={router} />
    </>
  );
};

export default App;
