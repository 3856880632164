import {
  DashboardRegisterButton,
  DashboardCloseButton,
} from "../DashboardButton";

import { approveWithdrawal } from "../../../api/apiService";

const DashboardPatientsModal = ({ showModalHandler, selectedRequest }) => {
  const handleApprove = async () => {
    try {
      if (!selectedRequest || !selectedRequest._id) {
        console.error("No request selected for approval.");
        return;
      }

      const response = await approveWithdrawal(selectedRequest._id);
      console.log("Withdrawal approved:", response);
      // Optionally, close the modal after approval
      showModalHandler();
      alert("Withdrawal successfully approved!");
    } catch (error) {
      let errorMessage = "Failed to approve the withdrawal. Please try again.";

      // Extract error message from the server response, if available
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      console.error("Error approving withdrawal:", error);
      alert(`Error: ${errorMessage}`);
    }
  };

  return (
    <div className="px-6 pt-5 pb-6 mb-4 bg-white shadow-xl rounded md:w-[550px]">
      <div className="flex items-center justify-between mb-4">
        <h4 className="text-xl">Withdrawal Request</h4>
        <button className="outline-[#f97729]" onClick={showModalHandler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 transition-colors hover:stroke-[#f97729]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      {selectedRequest ? (
        <div className="space-y-4">
          <p>
            <strong>Request ID:</strong> {selectedRequest?._id}
          </p>
          <p>
            <strong>User ID:</strong> {selectedRequest?.userId}
          </p>
          <p>
            <strong>Amount:</strong> {selectedRequest?.amount}
          </p>
          <p>
            <strong>Wallet:</strong> {selectedRequest?.receiverWallet}
          </p>
          <p>
            <strong>User Balance:</strong> {selectedRequest?.userBalance}
          </p>
          <p>
            <strong>Asset:</strong> {selectedRequest?.asset}
          </p>
          <p>
            <strong>Status:</strong> {selectedRequest?.withdrawalStatus}
          </p>

          <div className="flex justify-end space-x-2">
            <button
              className="py-2 px-4 text-white bg-[#045544] rounded shadow hover:bg-[#038c66]"
              onClick={handleApprove}
            >
              Approve
            </button>
            <button
              className="py-2 px-4 text-white bg-[#f97729] rounded shadow hover:bg-[#e06b25]"
              onClick={showModalHandler}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <p>No request selected.</p>
      )}
    </div>
  );
};

export default DashboardPatientsModal;
